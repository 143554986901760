export default function ProfileCard ({ name, title, description }) {
  return (
    <div className='relative rounded-lg drop-shadow overflow-hidden group w-full h-96'>
      <div className='absolute size-full bg-accent-500 transition-transform group-hover:delay-0 group-hover:scale-x-0 delay-300'>
        <img src={`${process.env.PUBLIC_URL}/images/${name}.png`} alt={name} className='w-full h-5/6 object-contain' />
        <h3 className='p-5 h-1/6 text-xl font-bold'>{name}</h3>
      </div>
      <div className='absolute size-full p-5 bg-accent-500 transition-transform scale-x-0 group-hover:scale-x-100 group-hover:delay-300'>
        <h3 className='text-xl font-bold'>
          {title}
        </h3>
        <br />
        {description}
      </div>
    </div>
  )
}
