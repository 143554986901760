import React from 'react'
import DOMPurify from 'dompurify'

export default function VisionDescription ({ title, content }) {
  return (
    <div className='flex flex-col gap-2.5'>
      <h4 className='text-xl font-bold'>{title}</h4>
      <p className='text-base' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
    </div>
  )
}
