export default function ContentSection ({ title, children }) {
  return (
    <section id={title[1].toLowerCase()} className='flex flex-col justify-between gap-10'>
      <h2 className='text-3xl sm:text-5xl lg:text-7xl font-bold drop-shadow'>
        {title[0]} <span className='text-accent-500'>{title[1]}</span>
      </h2>
      <div>
        {children}
      </div>
    </section>
  )
}
