export default function TimelineCard ({ author, date, comment }) {
  return (
    <li className='ml-4'>
      <div className='absolute size-3 bg-accent-500 rounded-full translate-y-1.5 -left-1.5 border border-white dark:border-black' />
      <time className='text-lg font-semibold text-accent-500'>
        {new Date(date).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })}
      </time>
      <h3 className='text-xl font-bold'>
        {author}
      </h3>
      {comment}
    </li>
  )
}
